import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Une idée de projet ?</h4>
        <p>
          Toujours prêt à me remonter les manches pour un projet intéressant.
          Contactez-moi !
        </p>

        <ul>
          {/* <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              123 Stree New York City , United States Of America 750065.
            </span>
          </li> */}
          {/* End li */}

          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">yoann.le-hir@orange.fr</span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">+33 6 21 87 64 21</span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
