import React from "react";
import ReactTooltip from "react-tooltip";

let imgFirebase = require("../../assets/images/stacks/firebase1.png");
let imgReact = require("../../assets/images/stacks/react.png");
let imgNode = require("../../assets/images/stacks/nodecarre.png");
let imgExpress = require("../../assets/images/stacks/expressjs.png");
let imgRedux = require("../../assets/images/stacks/redux.png");
let imgTypescript = require("../../assets/images/stacks/tscarre.png");
let imgreactQuery = require("../../assets/images/stacks/react-query.png");
let imgAws = require("../../assets/images/stacks/awscarre.png");
let imgJest = require("../../assets/images/stacks/jestlogo.png");

const SkillsContent = [
  {
    img: imgReact,
    skillName: "ReactJS / React Native",
  },
  {
    img: imgNode,
    skillName: "Node JS",
  },
  {
    img: imgFirebase,
    skillName: "Firebase",
  },
  {
    img: imgExpress,
    skillName: "Express",
  },
  {
    img: imgRedux,
    skillName: "Redux",
  },
  {
    img: imgTypescript,
    skillName: "Typescript",
  },
  {
    img: imgreactQuery,
    skillName: "React query",
  },
  {
    img: imgJest,
    skillName: "Jest",
  },
  {
    img: imgAws,
    skillName: "Amazon services",
  },
];

const TechStacks = () => {
  return (
    <>
      <ReactTooltip />
      <div className="row">
        {SkillsContent.map((val, i) => (
          <div className="col-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <div onMouseOver={() => <p>test</p>} className="icon">
                <img
                  data-tip={val.skillName}
                  src={val.img}
                  alt={val.skillName}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TechStacks;
