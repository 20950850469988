import React from "react";
import Skills from "../skills/Skills";
import TechStacks from "../../components/award/TechStacks";

const resumeContent = [
  {
    jobPosition: `Freelance`,
    jobType: `Remote`,
    jobDuration: `Nov 2020 - Aujourd'hui`,
    timeDuraton: `Part Time`,
    compnayName: "Développeur web / mobile",
    jobDescription: `Développement de sites web (statiques ou en reactJS) et développement d'applications mobiles sous React Native pour différentes missions. Intégration de nouvelles fonctionnalités. Mise en place de notifications push up. Création de portfolio et sites vitrines.`,
  },
  {
    jobPosition: `Lead Dév mobile`,
    jobType: `Remote`,
    jobDuration: `Mai 2022 - Novembre 2023`,
    timeDuraton: `Full Time`,
    compnayName: "KlubX",
    jobDescription: `Développement de l'application KlubX à partir de zéro. Mise en place de l'architecture de l'application, intégration de nouvelles fonctionnalités, gestion de l'équipe de développement.`,
  },
  {
    jobPosition: `Développeur mobile`,
    jobType: `Remote`,
    jobDuration: `Avril 2021 - Août 2021`,
    timeDuraton: `Part time`,
    compnayName: "Ozon Interactive",
    jobDescription: `Participation à l'élaboration de plusieurs applications mobiles pour le compte d'Ozon interactive. Reprise d'applications déjà développées, intégration de nouvelles fonctionnalités, refonte de l'interface utilisateur.`,
  },
  {
    jobPosition: `Fondateur`,
    jobType: `Remote`,
    jobDuration: `Jui 2020 - Aujourd'hui`,
    timeDuraton: `Full Time`,
    compnayName: "OneTribe",
    jobDescription: `Création d'une start up permettant de recenser tous les évènements sur Paris et ses alentours afin de faciliter l'accès à l'information dans le monde de la musique et de la nuit.`,
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div className="resume-row" key={i}>
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
              // End resume-row
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Skills</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <div className="aducation-box">
                <TechStacks />
              </div>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
