import { useTranslation } from "react-i18next";

const lngs = {
  fr: { nativeName: "Français" },
  en: { nativeName: "English" },
};

export const LanguageSwitch = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ position: "absolute", right: 10, top: 10, zIndex: 10 }}>
      {Object.keys(lngs).map((lng) => (
        <button
          key={lng}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
          }}
          type="submit"
          onClick={() => {
            i18n.changeLanguage(lng);
            console.log(i18n.resolvedLanguage);
          }}
        >
          {lngs[lng].nativeName}
        </button>
      ))}
    </div>
  );
};
