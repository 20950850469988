import React from "react";
import PortfolioTemplate from "./PortfolioTemplate";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PortfolioTemplate
        title="Lyynk"
        img="lyynk-cover.jpg"
        description={[
          <p>
            <b> Mission de 1 mois </b> pour le compte de l'entreprise Lyynk{" "}
            <br />
            Lyynk est spécialisée dans la santé mentale et le bien-être des
            jeunes. L'application met à disposition des jeunes une boîte à
            outils personnalisée afin de leur permettre de mieux se connaître et
            de mesurer leur état de bien-être.
          </p>,
          <p>
            <b>
              Fonctionnalités de l'application sur lesquelles j'ai travaillé
            </b>
            : <br /> • Création d'un chat pour la discussion en instantanée
            entre jeunes et adultes avec firebase cloud firestore et cloud
            functions • Mise en place des notifications pour la messagerie
            instantanée • Création de nouveaux écrans et nouvelles
            fonctionnalités • Correction de bugs
          </p>,
        ]}
        stack={[
          "Expo",
          "Firebase cloud firestore",
          "Firebase cloud function",
          "Docker",
        ]}
        urlApple="https://apps.apple.com/fr/app/lyynk/id6478778328"
        urlGoogle="https://play.google.com/store/apps/details?id=com.lyynk.app"
      />
      <PortfolioTemplate
        title="SmartBarrel"
        img="smartbarrel-cover.jpg"
        description={[
          <p>
            <b>Mission de 4 mois </b> pour le compte de l'entreprise Smart
            Barrel.
            <br />
            Smart Barrel est un entreprise américaine spécialisée dans le
            domaine du time tracking dans le secteur de la construction.
            L'application permet de suivre en temps réel les heures de travail
            des employés sur les chantiers avec un suivi de la position GPS
            ainsi que des heures travaillées sur les chantiers.
          </p>,
          <p>
            <b>
              Fonctionnalités de l'application sur lesquelles j'ai travaillé
            </b>
            : <br /> Refonte intégrale de l'application existante •
            Fonctionnalité de géolocalisation et geofencing • Fonctionnalité de
            time tracking • Création map avec pins • Offline mode
          </p>,
        ]}
        stack={[
          "Expo",
          "Expo Location",
          "React native maps",
          "Sentry",
          "Jest",
          "Rest Api",
        ]}
        urlApple="https://apps.apple.com/us/app/smartbarrel-mobilepunch/id1527784288?platform=iphone"
        urlGoogle="https://play.google.com/store/apps/details?id=com.mobilepunch"
      />
      <PortfolioTemplate
        title="KlubX - Maaser Labs"
        img="klubx4.jpg"
        description={[
          <p>
            <b> Mission de 1 an et demi </b> pour le compte de l'entreprise
            Maaser Labs <br />
            L'application KlubX est un nouveau réseau social dans le Web 3.0
            permettant d'aggréger dans une seule app tous les échanges autour
            des collections NFTs, là ou autrefois plusieurs applications étaient
            nécessaires pour échanger autour d'une collection (Twitter,
            Discord...)
          </p>,
          <p>
            <b>
              Fonctionnalités de l'application sur lesquelles j'ai travaillé
            </b>
            : <br /> • Reprise de l'application from scratch • Authentification
            avec wallet 3.0 • Création d'une section messenger avec socket •
            Création d'une section channel avec système de modération de contenu
            (ressemblant à Discord) • Optimisation du code pour les appareils
            low end • Push notification • Animations • Gestion des erreurs •
            Pipeline CI/CD
          </p>,
          <p>
            Développement de l'application la 1ère année tout seul puis un autre
            développeur était sous ma supervision pour accélérer le
            développement.
          </p>,
        ]}
        stack={["Expo", "Sentry", "Jest", "Rest Api", "Socket.io"]}
        urlApple="https://apps.apple.com/lu/app/klubx/id1644886921"
        urlGoogle="https://play.google.com/store/apps/details?id=space.klubx&hl=en_US"
      />

      <PortfolioTemplate
        title="Prions en Eglise - Bayard"
        img="prions3.jpg"
        description={[
          <p>
            <b> Mission de 3 mois et demi </b> pour le développement de la
            nouvelle application pour le groupe Bayard : Prions en Église.{" "}
            <br />
            L'application à destination des Chrétiens recense un grand nombre de
            contenus et sous différents formats (vidéos, images, textes,
            podcasts, playlists...).
          </p>,
          <p>
            <b>
              Fonctionnalités de l'application sur lesquelles j'ai travaillé
            </b>
            : <br /> • Keycloak (logiciel open source permettant
            l'authentification in-App et la gestion des accès) • Gestion des
            achats in-app avec Purchasely • Gestion des notifications locales
            avec Notifee • Onboarding • Deeplinking avec Firebase Dynamic link •
            Toast Notification • Gestion des accès pour les utilisateurs in app
            • Ajouts de nouveaux écrans
          </p>,
          <p>
            Développement de l'application dans une team de 2-4 développeurs
          </p>,
        ]}
        stack={[
          "React Native CLI",
          "AppCenter",
          "Android Studio",
          "XCode",
          "GitLab",
        ]}
        urlApple="https://apps.apple.com/fr/app/prions-en-eglise/id394393743"
        urlGoogle="https://play.google.com/store/apps/details?id=com.groupebayard.prionseneglise.iphone&hl=fr&gl=US"
      />
      <PortfolioTemplate
        title="OneTribe"
        img="onet2.png"
        description={[
          <p>
            <b> Développement intégral de OneTribe, </b> application recensant
            les évènements sur un périmètre donné et basé sur des paramètres
            prédéfinis (<b>front end et back end</b>).
          </p>,
          <p>
            <b> Fonctionnalités de l'application </b> : Liste des évènements
            avec différents filtres pour la recherche • Map avec Markers
            indiquant tous les évènements • Calendrier in-app des futurs
            évènements de l'utilisateur • Liké les évènements • Utilisation de
            la localisation et distance pour chaque évènement • Connexion
            regular/Google • Intégration deeplinking • Analytics avec Firebase •
            Notification Push avec Expo SDK
          </p>,
        ]}
        stack={[
          "React-Native",
          "Expo",
          "NodeJS",
          "Express",
          "Firebase",
          "Heroku",
          "Amazon AWS",
          "Sentry",
        ]}
        urlApple="https://apps.apple.com/us/app/onetribe/id1584177701"
        urlGoogle="https://play.google.com/store/apps/details?id=fr.onetribe"
      />
      <PortfolioTemplate
        title="Ulteam App"
        img="ulteamt.png"
        urlApple="https://apps.apple.com/fr/app/ulteam-tv/id1584616773"
        urlGoogle="https://play.google.com/store/apps/details?id=io.gonative.android.womypo"
        description={[
          <p>
            <b> Développement de l'application d'UlteamTV </b> : application
            permettant de faire du sport et de suivre des programmes. Reprise et
            refonte de l'application et ajout de nouvelles fonctionnalités.
          </p>,
          <p>
            <b>
              Fonctionnalités de l'application sur lesquelles j'ai travaillé{" "}
            </b>
            : Intégration de vidéos (avec reprise des vidéos) • Ajout des vidéos
            en favoris • Animation pour les vidéos • Gestion des programmes et
            validation • Gestion panel utilisateur
          </p>,
        ]}
        stack={[
          "React-Native",
          "Expo",
          "WebView",
          "Animated",
          "React-Query",
          "React Hook Form",
          "Redux",
        ]}
        order={1}
      />
      <PortfolioTemplate
        title="DeliverPop"
        img="deliverpop2.png"
        description={[
          <p>
            <b> Développement front End de DeliverPop côté utilisateur </b> :
            Application pour commander et se faire livrer des courses
            (uber-like).
          </p>,
          <p>
            <b>
              Fonctionnalités de l'application sur lesquelles j'ai travaillé
            </b>
            : Refonte graphique de l'application • Carte avec emplacement de
            l'utilisateur • Ajout panier • Gestion des produits • Gestion des
            commandes
          </p>,
        ]}
        stack={[
          "React-native",
          "Expo",
          "Redux",
          "React-hook-form",
          "Reanimated",
        ]}
      />
    </div>
  );
};

export default Portfolio;
